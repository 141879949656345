<template>
  <page>
    <page-row data-test="home">
      <page-column variant="8" gray scroll-container data-test="notifications">
        <div class="d-flex justify-content-between mb-3">
          <h3>{{ $t('view.home.notifications.title') }}</h3>
          <por-btn variant="primary" class="align-self-end" :to="{ name: 'new-request' }"
            >{{ $t('view.request.new.button') }}
          </por-btn>
        </div>
        <page-row>
          <div class="col-12 pt-3">
            <p v-if="!loading && !hasNotifications" class="text-center">
              {{ $t('view.home.notifications.message') }}
            </p>
            <loader v-if="loading" />
            <div v-if="!loading && hasNotifications" class="row">
              <div class="col-12 col-md-6 col-lg-3" v-for="request in deniedRequests" :key="request.id">
                <dashboard-card
                  :id="request.id"
                  :title="request.request.vessel.name"
                  :subtitle="request.ucrn"
                  :to="{
                    name: 'requests',
                    params: {
                      requestType: request.request.type.toLowerCase(),
                      id: String(request.id),
                    },
                  }"
                  >{{ showDashboardCardText(request) }}
                  <b-badge pill :variant="request.request.evaluationStatus | evaluationStatusColour" slot="status">
                    {{ request.request.evaluationStatus | evaluationStatusLabel }}
                  </b-badge></dashboard-card
                >
              </div>
            </div>
          </div>
        </page-row>
        <!-- need content/design for this section -->
      </page-column>
      <page-column variant="4" gray scroll-container data-test="tools-services-section">
        <tools-services-section />
      </page-column>
    </page-row>
  </page>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { BBadge } from 'bootstrap-vue';
import PageRow from 'poronline-shared-vue-components/components/page/row';
import PageColumn from 'poronline-shared-vue-components/components/page/column';
import Loader from 'poronline-shared-vue-components/components/loader';
import PorBtn from 'poronline-shared-vue-components/components/por-btn';
import ToolsServicesSection from './tools-services-section';
import DashboardCard from 'poronline-shared-vue-components/components/dashboard-card';
import Page from '../components/page/page';
import { evaluationStatusLabel } from '../filters/evaluation-status';
import { evaluationStatusColour } from '../filters/evaluation-status';

export default {
  name: 'HomePage',

  components: { Page, PageRow, PageColumn, DashboardCard, PorBtn, ToolsServicesSection, Loader, BBadge },

  data() {
    return {
      notified: false,
    };
  },

  computed: {
    ...mapState({
      loading: (state) => state.form.requestsLoading,
    }),
    ...mapGetters({
      deniedRequests: 'DENIED_REQUESTS',
    }),
    hasNotifications() {
      return this.deniedRequests && this.deniedRequests.length > 0;
    },
  },

  filters: {
    evaluationStatusLabel,
    evaluationStatusColour,
  },

  mounted() {
    this.showNewNotifications();
  },

  methods: {
    ...mapActions({
      showNewNotifications: 'SHOW_NEW_NOTIFICATIONS',
    }),

    showDashboardCardText(request) {
      if ('NOT_AGREED' === request.request.evaluationStatus) {
        if ('OUDEMAAS' === request.request.type) {
          return this.$t('view.home.notifications.oudeMaasNotAgreed');
        }
        return request.request.stateReason;
      } else if ('NOT_SATISFACTORY' === request.request.evaluationStatus) {
        return this.$t('view.home.notifications.notSatisfactory');
      }
      return '';
    },
  },
};
</script>
