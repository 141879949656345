import i18n from '../i18n';

export const evaluationStatusLabel = (evaluationStatus) => {
  return (
    {
      IN_PROGRESS: i18n.t('view.request.state.pending'),
      AGREED: i18n.t('view.request.state.approved'),
      AGREED_WITH_EXEMPTION: i18n.t('view.request.state.approvedExemption'),
      NOT_AGREED: i18n.t('view.request.state.denied'),
      SATISFACTORY: i18n.t('view.request.state.satisfactory'),
      NOT_SATISFACTORY: i18n.t('view.request.state.notSatisfactory'),
      ACKNOWLEDGED: i18n.t('view.request.state.acknowledged'),
      CANCELLED: i18n.t('view.request.state.cancelled'),
    }[evaluationStatus.toUpperCase()] ?? i18n.t('view.request.state.unknown')
  );
};

export const evaluationStatusColour = (evaluationStatus) => {
  return (
    {
      IN_PROGRESS: 'secondary',
      AGREED: 'success',
      AGREED_WITH_EXEMPTION: 'success',
      NOT_AGREED: 'warning',
      SATISFACTORY: 'success',
      NOT_SATISFACTORY: 'warning',
      ACKNOWLEDGED: 'secondary',
      CANCELLED: 'light',
    }[evaluationStatus.toUpperCase()] ?? 'secondary'
  );
};
