<template>
  <div class="tools-services border bg-white p-5">
    <h3 class="mb-4">{{ $t('view.home.tools.title') }}</h3>
    <div v-for="(services, index) in toolsServices" :key="index" class="row mb-5">
      <div class="col-3 col-md-2">
        <img class="logo logo--tools-services" :src="services.logo" alt="" />
      </div>
      <div class="col">
        <h4 class="mb-1">{{ $t(services.titleTranslationKey) }}</h4>
        <p class="mb-2">{{ $t(services.subTitleTranslationKey) }}</p>
        <div v-if="services.markdownTranslation" v-markdown="$t(services.markdownTranslation)"></div>
        <a
          v-if="services.linkUrl && services.buttonTranslationKey"
          :href="services.linkUrl"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t(services.buttonTranslationKey) }}<i class="icon icon-external-link pl-2 text-decoration-none" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import KingLogo from 'assets/images/king-logo.png';
import PinBasLogo from 'assets/images/pinbas-logo.png';
import PlaceholderLogo from 'assets/images/placeholder-logo.png';
import InfoLogo from 'assets/images/info-logo.svg';
import HarbourMapLogo from 'assets/images/harbour-map-logo.svg';

export default {
  name: 'ToolsServicesSection',
  data() {
    return {
      toolsServices: [
        {
          titleTranslationKey: 'view.home.tools.king.title',
          subTitleTranslationKey: 'view.home.tools.king.subTitle',
          logo: KingLogo,
          linkUrl: 'https://king.portofrotterdam.com',
          buttonTranslationKey: 'view.home.tools.king.link',
        },
        {
          titleTranslationKey: 'view.home.tools.pin.title',
          subTitleTranslationKey: 'view.home.tools.pin.subTitle',
          logo: PinBasLogo,
          linkUrl: 'https://pin.portofrotterdam.com',
          buttonTranslationKey: 'view.home.tools.pin.link',
        },
        {
          titleTranslationKey: 'view.home.tools.tidalGate.title',
          subTitleTranslationKey: 'view.home.tools.tidalGate.subTitle',
          logo: PlaceholderLogo,
          linkUrl: 'https://lttp-public.mx-systems.nl/',
          buttonTranslationKey: 'view.home.tools.tidalGate.link',
        },
        {
          titleTranslationKey: 'view.home.tools.map.title',
          subTitleTranslationKey: 'view.home.tools.map.subTitle',
          logo: HarbourMapLogo,
          linkUrl: 'https://experience.arcgis.com/experience/0127b94d675f48dd80702b5b81be6020',
          buttonTranslationKey: 'view.home.tools.map.link',
        },
        {
          titleTranslationKey: 'view.home.tools.hcc.title',
          subTitleTranslationKey: 'view.home.tools.hcc.subTitle',
          logo: InfoLogo,
          markdownTranslation: 'view.home.tools.hcc.link',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped="scoped">
.tools-services {
  .icon {
    font-size: 1.1428rem;
    line-height: 1.3571rem;
  }

  .logo {
    width: 100%;
    height: auto;

    &--tools-services {
      max-width: 80px;
    }
  }
}
</style>
